import React from 'react'
import { Link } from 'react-router-dom'
import img from "./bg.png"
import logo from './logo.png'


const Header = () => {

    
    
    
  return (
      <div className="header">
            <div>
            <div>
        <div className="header-nav">

        <div className="header-nav-logo">
            <img src={logo} alt="" />
        </div>

        <div className="header-nav-menu">
            <span><Link title='call link' to="tel:+919658139139" target='_blank'>Call Now</Link></span>
        </div>

        </div>

        <div className="header-main">

        <div className="header-main-col">

            <h1 className="header-main-heading-1">
            Get Best <span>Digital Marketing</span>  <br />& <span>Web Development </span>Services


            </h1>

            <div className="header-main-points">
                <div className="header-main-points-txt-1"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Search Engine Optimization  Service. Please contact me."> Search Engine Optimization (SEO) </Link></div>
                <div className="header-main-points-txt-2"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Google Ads Service. Please contact me."> Google Ads</Link></div>
                <div className="header-main-points-txt-3"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Meta Ads Service. Please contact me."> Meta Ads (Facebook | Instagram)</Link></div>
                <div className="header-main-points-txt-4"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Logo Design  Service. Please contact me."> Logo Design </Link></div>
                <div className="header-main-points-txt-5"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Social Media Marketing Service. Please contact me."> Social Media Marketing</Link></div>
                <div className="header-main-points-txt-6"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Local SEO Service. Please contact me."> Local SEO (GMB)</Link></div>
                <div className="header-main-points-txt-7"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Web Development Service. Please contact me."> Website Development</Link></div>
                <div className="header-main-points-txt-8"><span>❯❯</span> <Link target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Promotional video Service. Please contact me."> Promotional Videos</Link></div>
            <div className="header-main-points-txt"></div>
    
            </div>
                {/* <div className="header-main-heading-2"><span>❖</span> No Hidden or Extra Charges</div> */}

        </div>
        <div className="header-main-col-2">
            <img src={img} alt="header img" title='header img' />
        </div>


        </div>

        <div className="header-button">
            <Link title='call link' target='_blank' to="tel:+91 9658139139">Enquire Now</Link>
            <Link title='whatsapp link' target='_blank' to="https://wa.me/+919658139139?text=Hello ! I'm Interested in Digital Marketing / Web Development Services. Please contact me.">WhatsApp</Link>
        </div>

        </div>
    </div>
    </div>
  )
}

export default Header