import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";
import Header from "../new/Header";

const ErrorPage = () => {

  const navigate = useNavigate()



useEffect(() => {
    navigate("/");
  },[])
  
    
  
  
  return <div>

    {/* <>
    <NavbarComponent/>
    
    <div className="error-page">
        <h1>404 Not Found</h1>
        <p onClick={homePage}>Your visited page not found. You may go home page.</p>
        <Link title="home page" to="/">Back to home page</Link>
    </div>
    
    <FooterComponent/>
    </> */}
    
    <Header/>
    
    
  </div>;
};

export default ErrorPage;
