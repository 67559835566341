import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./css/components/NavbarComponent.css"
import "./css/components/HeaderComponent.css"
import "./css/components/OurMessageComponent.css"
import "./css/components/CallUsComponent.css"
import "./css/components/OurBusinessesComponent.css"
import "./css/components/FooterComponent.css"
import "./css/components/BreadcrumbComponent.css"
import "./css/components/PrivacyPolicyComponent.css"
import "./css/components/MediaPageComponent.css"
import "./css/components/SitemapComponent.css"
import "./css/components/ScrComponent.css"
import "./css/components/ErrorPage.css"
import "./css/components/ContactComponent.css"
import "./css/components/CareerComponent.css"
import "./css/components/BusinessComponent.css"
import "./css/components/AboutComponent.css"

// import HomePage from "./pages/HomePage";
// import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
// import TermsConditionPage from "./pages/TermsConditionPage";
// import MediaPage from "./pages/MediaPage";
// import SitemapPage from "./pages/SitemapPage";
// import CsrPage from "./pages/CsrPage";
// import ContactPage from "./pages/ContactPage";
// import CareerPage from "./pages/CareerPage";
// import BusinessPage from "./pages/BusinessPage";
// import AboutPage from "./pages/AboutPage";
import CpanelPage from "./pages/CpanelPage";
import ErrorPage from "./pages/ErrorPage";
import ScrollToTop from "./components/others/ScrollToTop";
import ScrollButton from "./components/others/ScrollButton";


import Header from "./new/Header";
import './new/Header.css'
import './new/Animation.css'



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<HomePage />}></Route>
          <Route path="/corporate" element={<CsrPage />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
          <Route path="/career" element={<CareerPage />}></Route>
          <Route path="/business" element={<BusinessPage />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>

          
          <Route path="/privacy" element={<PrivacyPolicyPage />}></Route>
          <Route path="/terms-condition" element={<TermsConditionPage />}></Route>
          <Route path="/media" element={<MediaPage />}></Route>
          <Route path="/sitemap" element={<SitemapPage />}></Route> */}

          
          <Route path="/cpanel" element={<CpanelPage />}></Route>

          <Route path="/*" element={<ErrorPage />}></Route>
          

          <Route path="/" element={<Header />}></Route>
          
        </Routes>
        <ScrollToTop/>
        <ScrollButton/>
      </BrowserRouter>
    </div>
  );
}

export default App;
